
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PointTypeDetail } from '@/types/patrol'
import { ElForm } from 'element-ui/types/form'
import { iconUrl } from '@/utils/config'

@Component
export default class PointTypeSet extends Vue {
  @Prop({ default: false }) private isShow!: boolean

  private editIndex = -1
  private loading = false
  private tableData: Array<PointTypeDetail> = []
  private iconList: Array<{ icon: string; id: string }> = []
  private elementList = []
  private formData: PointTypeDetail = {
    facilityTypeName: '',
    elementId: '',
    icon: ''
  }

  private rules = {
    facilityTypeName: [
      { required: true, message: '请输入类型名称', trigger: ['blur', 'change'] }
    ],
    icon: [
      { required: true, message: '请选择类型图标', trigger: ['blur', 'change'] }
    ]
  }

  get iconPathPrefix () {
    return iconUrl
  }

  created () {
    this.loadElementList()
    this.loadIconList()
    this.loadData()
  }

  // 巡查要素
  loadElementList () {
    this.$axios.get(this.$apis.patrol.selectElementByList).then(res => {
      this.elementList = res.list || []
    })
  }

  loadIconList () {
    this.$axios.get(this.$apis.patrol.selectIconByList).then(res => {
      this.iconList = res || []
    })
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.patrol.selectFacilityTypeByList).then(res => {
      this.tableData = res.list
      // 滚动到表格顶部
      this.$nextTick(() => {
        (this.$refs.table as any).bodyWrapper.scrollTop = 0
      })
    }).finally(() => {
      this.loading = false
    })
  }

  // 判断表格状态
  validateState () {
    if (this.editIndex >= 0) {
      this.$message.warning('请先保存')
      return false
    }
    return true
  }

  onAdd () {
    if (this.validateState()) {
      this.editIndex = this.tableData.length
      this.formData = {
        facilityTypeName: '',
        icon: '',
        elementId: ''
      }
      this.tableData.push({ ...this.formData })
      // 滚动到表格最底部
      this.$nextTick(() => {
        const table = this.$refs.table as any
        table.bodyWrapper.scrollTop = table.bodyWrapper.scrollHeight
      })
    }
  }

  async onEdit (index: number) {
    if (this.validateState()) {
      this.formData = {
        ...this.tableData[index]
      }
      this.editIndex = index
    }
  }

  onCancel () {
    this.editIndex = -1
    this.loadData()
  }

  onSave () {
    (this.$refs.infoForm as ElForm).validate().then(() => {
      const url = this.formData.facilityTypeId ? this.$apis.patrol.updateFacilityType : this.$apis.patrol.insertFacilityType
      this.$axios.post(url, this.formData).then(res => {
        this.onCancel()
      })
    })
  }

  handleCommandItem (index: number) {
    this.formData.icon = this.iconList[index].icon
  }

  onDelete (facilityTypeId: string) {
    if (this.validateState()) {
      this.$confirm('确定删除此类型,是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$apis.patrol.deleteFacilityType, {
          facilityTypeId
        }).then(() => {
          this.loadData()
        })
      })
    }
  }

  onClose () {
    this.$emit('update:isShow', false)
  }
}
